import {store} from "@/functions/store"
import Prices from "../../data/default-prices.json"
import getPriceSlashData from "@/functions/getPriceSlashData"
import getBannerPriceConfigs from "@/functions/getBannerPriceConfigs"
import getPriorYearPriceData from "@/functions/getPriorYearPriceData"

const getPrices = async () => {
	const isTimeTravelTesting = process.env.VUE_APP_TIME_TRAVEL
	let apiSubdomain = process.env.VUE_APP_API_SUBDOMAIN
	if (isTimeTravelTesting === "true" && location.href.includes("date=") && location.href.includes("data=prod")) {
		apiSubdomain = "api"
	}
	let priceScheduleApi = "https://" + apiSubdomain + "." + process.env.VUE_APP_SITENAME + ".com" + process.env.VUE_APP_MONOSERVICE_PATH + "/prices/" + process.env.VUE_APP_TAX_YEAR


	await fetch(priceScheduleApi, {
		method: "post",
		credentials: "include",
		headers: {
			"content-type": "application/json",
		},
		body: JSON.stringify({
			appName: "freetaxusa"
		})
	})
	.then(async (response) => {
		store.prices = await response.json()
	})
	.catch(() => {
		store.prices = Prices.data
	})

	store.prices = await getPriorYearPriceData(store.prices)
	store.prices = await getPriceSlashData(store.prices)
	store.prices = await getBannerPriceConfigs(store.prices)
}

export default getPrices
