import Configs from "@/../data/default-configs.json"
import {store} from "@/functions/store"
import getExtensionMessageConfigs from "@/functions/getExtensionMessageConfigs"
import getBannerConfigs from "@/functions/getBannerConfigs"
import getFreeFileConfigs from "@/functions/getFreeFileConfigs"

const getConfigs = async () => {
	const isTimeTravelTesting = process.env.VUE_APP_TIME_TRAVEL
	let apiSubdomain = process.env.VUE_APP_API_SUBDOMAIN
	if (isTimeTravelTesting === "true" && location.href.includes("date=") && location.href.includes("data=prod")) {
		apiSubdomain = "api"
	}
	let configApi = "https://" + apiSubdomain + "." + process.env.VUE_APP_SITENAME + ".com" + process.env.VUE_APP_MONOSERVICE_PATH + "/configs/" + process.env.VUE_APP_TAX_YEAR


	await fetch(configApi, {
		method: "get",
		credentials: "include",
	})
	.then(async (response) => {
		store.configs = await response.json()
	})
	.catch(() => (store.configs = Configs.data))

	store.loadingClass = 'fade-in-up'

	store.configs = await getExtensionMessageConfigs(store.configs)
	store.configs = await getBannerConfigs(store.configs)
	store.configs = await getFreeFileConfigs(store.configs)
}

export default getConfigs
